<template>
	<div style="padding:5px;">
		<el-tabs v-model="activeName" class="demo-tabs">
			<el-tab-pane label="我送出的" name="1">
				<list index="1"></list>
			</el-tab-pane>
		    <el-tab-pane label="送给我的" name="2">
				<list index="2"></list>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	import List from "./List/List"
	export default {
		components:{ List },
		data() {
			return{
				activeName:"1"
			}
		},
		created(){
			
		},
		methods:{
			
			setHeight(){
				let h = document.documentElement.clientHeight || document.body.clientHeight;
				this.height = h - 440;
			}
		},
		beforeMount(){
			this.setHeight();
		},
		mounted(){
			window.addEventListener('resize', this.setHeight, false)
		},
		beforeDestroy(){
			window.removeEventListener('resize',this.setHeight, false)
		}
	};
</script>
<style scoped>
	.boxListCon{
		margin-top:20px;
	}
	.boxListCon .cart{
		font-size:16px;
		font-weight:bold;
	}
	.boxListCon .cart .el-icon{
		vertical-align: sub;
	}
	.user{
		display: flex;
		justify-content: center;
		line-height: 50px;
	}
	.user .text{
		align-self: center;
	}
	.user span{
		display: inline-block;
	}
	.user span:first-child{
		vertical-align: bottom;
	}
	.user .text div:first-child{
		color:#909399;
	}
	.user .text div:last-child{
		color:#000;
		font-weight:bold;
	}
	.user img{
		width:50px;
		height:50px;
		border-radius: 10px;
		vertical-align: text-top;
		margin-right:8px;
	}
	.el-table{
		background:rgb(243, 243, 243);
	}
</style>